
import React, { useState } from 'react';
import { graphql } from "gatsby";
import LayoutPublic from "../components/layouts/layoutPublic"
import Header from "../components/header";
import AdCardLandscape from '../components/ad/Landscape';
import Seo from '../components/Seo/';
// import ReactMarkdown from 'react-markdown'
import Wrapper from '../components/Wrapper'
import AdLoader from '../components/ad/AdLoader'

import {
  Box,
  Flex,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react"

const siteConfig = require('../../config')

const Seller = (props) => {

  const { name, slug, illu, ad, desc } = props.data.seller
  const [adSingleID, setAdSingleID] = useState(false)

  const title = {
    "en":
      name + ' used boats for sale'
    ,
    "fr":
      name + ' annonces bateaux occasions à vendre'
  };
  const description = {
    "en":
      name + ': all the ad at the best used price. Photos and descriptions of the boats for sale ads price, purchase, selling.'
    ,
    "fr":
      name + " toutes les annonces au meilleur prix d'occasion. Photos et descriptions du bateaux à vendre annonces prix, achat, vente"
  };

  return (
    <LayoutPublic
      hasNav={true}
      lang={process.env.GATSBY_LANG}
      meta={{
        title: name + ' les annonces d\'occasion en ligne',
        description: "Actualités Wanaboat.fr",
        slug: "/"
      }}
    >
      <Seo
        title={title[process.env.GATSBY_LANG]}
        description={description[process.env.GATSBY_LANG]}
        slug={slug}
      />
      <Header
        illuUrl={false}
        illuUrlQueryString={false}
        name={name}
        breadcrumbsItems={[
          { "name": process.env.GATSBY_LANG === 'fr' ? "Capitainerie" : "Harbour office", "slug": siteConfig.links.about[process.env.GATSBY_LANG] },
          { "name": process.env.GATSBY_LANG === 'fr' ? "Vendeurs" : "Sellers", slug: siteConfig.links.sellersHub[process.env.GATSBY_LANG] },
          { "name": name, slug: '/' + slug }
        ]
        }
        h1={name}
        slug={slug}
        descShort={
          <Box className="wysiwyg">
            {/* <ReactMarkdown source={desc.desc} /> */}
          </Box>
        }
      // desc={this.renderSellerDesc()}
      />

      <Wrapper maxW='800px'>
        {ad.map((edge, i) =>
          <Box
            key={edge.id}
          >
            <AdCardLandscape
              to={edge.slug}
              name={edge.name}
              price={edge.price}
              brand={(edge.refBrand) ? edge.refBrand.name : null}
              date={edge.date}
              region={edge.department}
              images={edge.pictures}
              publicationDate={edge.publicationDate}
              isBoat={edge.isBoat}
              isSail={edge.isSail}
              isPart={edge.isPart}
              isTrolley={edge.isTrolley}
              isInIframe={true}
              openAction={
                () => {
                  setAdSingleID(edge.contentful_id)
                }}

            />
          </Box>
        )}
      </Wrapper>
      {adSingleID ? (
        <Modal
          size={{ base: "100vw", md: "600px", xl: "700px" }}
          top={0}
          bottom={0}
          scrollBehavior="outside"
          closeOnOverlayClick={true}
          isOpen={adSingleID ? true : false}
          onClose={
            () => {
              setAdSingleID(false)
            }
          }
        >
          <ModalOverlay />
          <ModalContent
            my={{ base: 0, lg: "2rem" }}
            borderRadius="10px"
          >
            <ModalBody p="0" radius="lg">
              <AdLoader
                adID={adSingleID}
                handleUnload={
                  () => {
                    setAdSingleID(false)
                  }
                }
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
    </LayoutPublic>
  )
}

export default Seller

export const pageQuery = graphql`
  query sellerQuery($id: String!){
    seller: contentfulSeller(contentful_id: {eq: $id}) {
      slug
      name
      phone
      #illu{
      #  fixed{
      #    src
      #  }
      #}
      logo {
        file {
          url
        }
      }
      desc { desc }
      #hours{
      #  childMarkdownRemark {
      #      html
      #      htmlAst
      #  }
      #}
      #desc{
      #  childMarkdownRemark {
      #      html
      #      htmlAst
      #  }
      #}
      location{
          lat
          lon
      }
      ad{
        id
        contentful_id
        name
        slug
        price
        department
        isBoat
        isPart
        isSail
        isTrolley
        refBrand {
          name
        }
        images {
          url
        }
        pictures{ url }
        date(formatString: "Y")
        publicationDate(formatString: "D/M/Y")
        refUnivers {
          slug
        }
        location {
          lat
          lon
        }
      } 
    }
  }
`