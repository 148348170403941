import React from "react"
import { Link as GatsbyLink } from "gatsby"

import {
    Avatar,
    Button,
    Box,
    Flex,
    Grid,
    Heading,
    Image,
    Link,
    Stack,
    Text,
} from "@chakra-ui/react"
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import avatar from '../../static/images/wanaboat-avatar.jpg'
import StructuredBreadcrumbs from "../../components/StructuredData/breadcrumbs"
import HeroIllu from './illu'
import { useIntl } from 'react-intl'

import { Padder } from '../Padder'

import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import Nav from "../nav";
import { wrapperPadding } from "../../utils/wrapperPadding";
// import css from "./hero.module.scss";

const siteConfig = require("../../../config.js");

const Header = (props) => {
    const intl = useIntl()

    const {
        nav,
        desc,
        descShort,
        name,
        image,
        h1,
        illuUrl,
        breadcrumbsItems,
        backLinkTarget,
        illuUrlQueryString } = props
    return (
        <Grid
            templateColumns={{ lg: '1fr 1fr' }}
            gap={{ lg: '2rem', xl: '4rem', xxl: '5rem', xxxl: '5em' }}
            mb={{ base: '1rem', lg: '5rem' }}
            marginTop={{ lg: 'auto' }}

        >

            <Stack
                zIndex="base"
                position={{ base: 'relative' }}
                py={{ base: "1rem", lg: "0" }}
                mt={{ base: "0", xl: "initial" }}
                spacing={{ base: "1rem", xl: "2rem" }}
                // maxWidth={{ base: "650px", lg: "680px", xl: "750px" }}
                borderRadius="4px"
                order={{ base: '2', xl: '1' }}
                {...wrapperPadding()}
            >
                {nav ?
                    <Nav />
                    : null}
                {breadcrumbsItems ?
                    <StructuredBreadcrumbs
                        items={breadcrumbsItems}
                    />
                    : null}
                <Heading as="h1"
                    fontSize={{ base: '22px', xl: '4xl', xxxl: '5xl' }}
                    color={{ base: "gray.700", xl: "gray.700" }}
                    mb={{ base: 2, lg: 5, xl: 10 }}
                >
                    {h1 ? h1 : <><Text as="span" color={{ xl: "blue.brand" }}>{name}</Text> <FormattedMessage id="header.h1" /></>}
                </Heading>

                {descShort ?
                    <Box>
                        {descShort}
                    </Box>
                    : null}
                {desc ?
                    <Box>
                        {desc}
                    </Box>
                    : null}

                <Box>
                    <Button
                        as={GatsbyLink}
                        to={siteConfig.links.publish[process.env.GATSBY_LANG]}
                        variant={'outline'}
                        borderRadius={'full'}
                        borderColor={'primary'}
                        color={'primary'}
                    >
                        <FormattedMessage id="cta.publish.with.univers" values={{ name: name.toLowerCase() }} />
                    </Button>
                </Box>
            </Stack>

            <Flex
                order={{ base: '1', lg: '2' }}
            >

                <Box
                    as='figure'
                    position={{ base: 'relative', xl: 'relative' }}
                    top='0'
                    left='0'
                    h={{ base: 'auto', lg: 'auto' }}
                    // background={{ lg: `url(${roundedShape}) no-repeat top left` }}
                    display={image ? 'block' : 'none'}

                >
                        <>
                            {image && image.gatsbyImageData ?
                                <Box
                                    borderRadius={{ base: 0, lg: 'full' }}
                                    overflow={'hidden'}

                                >
                                    <GatsbyImage
                                        image={image.gatsbyImageData}
                                        alt={`${name} ${intl.formatMessage({ id: 'main.used' })}`}

                                    />

                                </Box>

                                :
                                "nana"
                                // <Box

                                //     as='picture'
                                //     display='none'
                                //     w={{ base: '100%', lg: ' 100%' }}
                                //     h={{ base: '100%', lg: ' 100%' }}
                                //     mt={{ lg: '3rem' }}
                                //     ml={{ lg: '1rem' }}
                                // >
                                //     <source srcSet={`${process.env.GATSBY_BASE_IMG_URL}${illuUrl.slice(1, 200)}?twic=v1/cover=468x468/format=avif/quality=40`} type="image/avif" />
                                //     <source srcSet={`${process.env.GATSBY_BASE_IMG_URL}${illuUrl.slice(1, 200)}?twic=v1/cover=468x468/format=webp/quality=40`} type="image/webp" />
                                //     <Image
                                //         src={`${process.env.GATSBY_BASE_IMG_URL}${illuUrl.slice(1, 200)}?twic=v1/cover=468x468/format=jpeg/quality=40`}
                                //         p={0}
                                //         m={'auto'}
                                //         w='468px'
                                //         h='468px'
                                //         objectFit="cover"
                                //         alt={`${name} ${intl.formatMessage({ id: 'main.used' })}`}
                                //         title={`${name} ${intl.formatMessage({ id: 'main.used' })}`} loading='lazy'
                                //     />

                                // </Box>
                            }
                        </>

                    {/* <svg width="0" height="0">
                        <defs>
                            <clipPath id="headerIlluMask">
                                <path d="M207.488 45.6782C267.246 -14.0796 364.133 -14.0796 423.891 45.6782C483.648 105.436 483.648 202.323 423.891 262.08L261.93 424.041C202.171 483.799 105.285 483.799 45.5269 424.041C-14.2308 364.284 -14.2308 267.397 45.5269 207.639L207.488 45.6782Z" fill="#0042DA" />
                            </clipPath>
                        </defs>
                    </svg> */}
                    <Text
                        position='absolute'
                        opacity='.9'
                        bottom='0rem'
                        right='0rem'
                        p='.15rem .45rem'
                        bg='white'
                        fontSize='12px'
                        zIndex='base'
                        as='figcaption'>{name} <FormattedMessage id='main.used' />
                    </Text>


                    <Flex
                        display={{ base: 'flex', lg: 'none' }}
                        alignItems={'center'}
                        bgColor='primary'
                        color='white'
                        fontWeight={'500'}
                        borderRadius={'full'}
                        px={3}
                        py={1}
                        position='absolute'
                        zIndex='docked'
                        left='1rem'
                        top='1rem'
                        as={GatsbyLink}
                        to={
                            backLinkTarget ? backLinkTarget : '..'
                        }
                    >
                        <Box
                            color='white'
                            mr={1}
                        >
                            <BiLeftArrowAlt />
                        </Box>
                        <FormattedMessage id="main.back" />

                    </Flex>
                </Box>
            </Flex>
        </Grid>

    )
}

export default Header;