import React from "react";
import {
    Box,
    Image,
    Text,
    Flex
} from "@chakra-ui/react";
// import css from "./hero.module.scss";
import logo from '../../static/svg/logo.svg'
const siteConfig = require('../../../config.js')

const HeroIllu = (props) => {
    const name = (props.title) ? props.title : "Bateau";
    const defaultIlluUrl = `/photos/illustrations/laser.jpg`
    const illuUrl = (props.image === null) ? defaultIlluUrl : props.image

    const illuUrlBuild = (qs) => {
        if (props.illuUrlQueryString) {
            return illuUrl + qs
        } else {
            return (illuUrl)
        }
    }
    return (

        <Box
            position={{ base: "relative", md: "absolute" }}
            top={0}
            right={0}
            zIndex="base"
            w={{ base: "calc( 100vw - 10px )", md: "500px" }}
            h={{ base: "22vh", md: "500px" }}
        >
            <Flex
                visibility={{ md: "hidden" }}
                position="absolute"
                top={0}
                left={0}
                p={"5"}
                pb={"50px"}
                background="linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)"
                width="100%"
                justifyContent="center"
                alignItems="center"
                zIndex="sticky"
            >
                <Image
                    w="35px"
                    h="35px"
                    mr={2}
                    id="brand-logo"
                    loading="eager"
                    src={logo}
                    alt={props.alt}
                    title={props.title}
                />
                <Text
                    fontWeight="medium"
                    fontSize={"3xl"}
                    color="gray.700"
                >
                    {siteConfig.siteNames[process.env.GATSBY_LANG]}
                </Text>
            </Flex>
            <picture className={null
                // css.headerIllu
            }>
                <source
                    type="image/webp"
                    // srcSet={`${illuUrl}?bg=333333&width=400&height=400&quality=55&format=webp`}
                    srcSet={illuUrlBuild('?bg=333333&width=400&height=400&quality=55&format=webp')}
                />
                <source
                    // type="image/jpeg"
                    srcSet={illuUrlBuild('?bg=333333&width=400&height=400&quality=55&format=jpeg')}
                />
                <img
                    alt={`${name} occasion Wanaboat`}
                    title={`${name} d'occasion Wanaboat`}
                    aria-label={`${name} d'occasion Wanaboat`}
                    loading="eager"
                    // src={`${illuUrl}?bg=333333&width=400&height=400&quality=55&format=jpeg`}
                    src={illuUrlBuild('?bg=333333&width=400&height=400&quality=55&format=webp')}
                />

                <svg width="0" height="0">
                    <defs>
                        <clipPath id="headerIlluMask">
                            <path fillRule="evenodd" clipRule="evenodd" d="M207.132 0.12207L60.6676 146.587C-20.2226 227.478 -20.2226 358.626 60.6678 439.517C98.8814 477.73 148.311 497.892 198.36 500H215.905C265.954 497.892 315.384 477.73 353.598 439.517L353.898 439.216L499.203 293.911V292.192L498.891 291.88H499.203V0H207.295V0.28418L207.132 0.12207Z" fill="#FF0000" />
                        </clipPath>
                    </defs>
                </svg>
            </picture>
            <Box
                display={{ base: "none", md: "block" }}
                position="absolute"
                top={"9rem"}
                right={"0"}
            >
                <svg width="321" height="321" viewBox="0 0 321 321" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M132.979 0.0783409L38.9487 94.1088C-12.9829 146.04 -12.9829 230.238 38.9487 282.17C63.4829 306.704 95.2188 319.647 127.352 321H138.607C170.74 319.647 202.475 306.704 227.01 282.17L320.488 188.691V187.587L320.288 187.387H320.488V0H133.083V0.182537L132.979 0.0783409Z" fill="#0042DA" />
                </svg>
            </Box>
        </Box>


        // <Box
        //     position={{ base: "relative", md: "absolute" }}
        //     top={0}
        //     right={0}
        //     display={{ md:"none", xl:"initial"}}
        //     w={{ base: "100vw", xl: "400px" }}
        //     h={{ base: "22vh", xl: "400px" }}
        // >
        //     <Flex
        //         visibility={{ md: "hidden" }}
        //         position="absolute"
        //         top={0}
        //         left={0}
        //         p={"5"}
        //         pb={"50px"}
        //         background="linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)"
        //         zIndex="dropdown"
        //         width="100%"
        //         justifyContent="center"
        //         alignItems="center"
        //     >
        //         <Image
        //             w="35px"
        //             h="35px"
        //             mr={2}
        //             id="brand-logo"
        //             loading="eager"
        //             src={`/svg/logo.svg`}
        //             alt={props.alt}
        //             title={props.title}
        //         />
        //         <Text
        //             fontWeight="medium"
        //             fontSize={"3xl"}
        //             color="gray.700"
        //         >
        //             Wanaboat.fr
        //         </Text>
        //     </Flex>
        //     <picture className={css.headerIllu}>
        //         <source
        //             type="image/webp"
        //             srcSet={`${illuUrl}?bg=333333&width=400&height=400&quality=55&format=webp`}
        //         />
        //         <source
        //             type="image/jpeg"
        //             srcSet={`${illuUrl}?bg=333333&width=400&height=400&quality=55&format=jpeg`}
        //         />
        //         <img
        //             alt={`${name} occasion Wanaboat`}
        //             title={`${name} d'occasion Wanaboat`}
        //             aria-label={`${name} d'occasion Wanaboat`}
        //             loading="eager"
        //             src={`${illuUrl}?bg=333333&width=400&height=400&quality=55&format=jpeg`}
        //         />
        //     </picture>
        //     <svg width="0" height="0">
        //         <defs>
        //             <clipPath id="headerIlluMask">
        //                 <path fillRule="evenodd" clipRule="evenodd" d="M157.631 0H527V238.232L293.808 372.866C200.302 426.852 80.7358 394.814 26.75 301.308C-27.2358 207.802 4.80177 88.2358 98.308 34.25L157.631 0Z" fill="#0042DA" />
        //             </clipPath>
        //         </defs>
        //     </svg>
        //     <Box
        //         display={{ base: "none", md: "block" }}
        //         position="absolute"
        //         top={12}
        //         right={"0"}
        //         zIndex={'base'}
        //     >
        //         <svg width="397" height="372" viewBox="0 0 397 372" fill="none" xmlns="http://www.w3.org/2000/svg">
        //         <path fillRule="evenodd" clipRule="evenodd" d="M396.475 0L108.608 0L97.7826 6.25C4.27643 60.2358 -27.7612 179.802 26.2247 273.308C80.2105 366.814 199.776 398.852 293.283 344.866L396.475 285.288V0Z" fill="#0042DA"/>
        //         </svg>

        //     </Box>
        // </Box>
    )
}

export default HeroIllu;